import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes, stagger, query } from '@angular/animations'
import * as $ from 'jquery'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './home.component-title.css'],
  animations: [
    trigger('homeAnimation', [
      transition('* => *', [
        query('img, p, h3, h5' , style({opacity:0})),
        query('img',
            animate('5000ms', style({opacity:1})),
          ),
        query('p',
          stagger('90ms', [
            animate('1000ms', style({opacity:1})),
          ])),
        query('h3, h5',
            animate('1000ms', style({opacity:1})),
          ),
        ])
      ]),

    ]

})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // this.titleParalax()
  }

  titleParalax(){
    var card = $(".home");

    $(document).on("mousemove",function(e) {
      var ax = -($(window).innerWidth()/2- e.pageX)/225;
      var ay = ($(window).innerHeight()/2- e.pageY)/175;
      card.attr("style",
      "transform: rotateY("+ax+"deg) rotateX("+ay+"deg);-webkit-transform: rotateY("+ax+"deg) rotateX("+ay+"deg);-moz-transform: rotateY("+ax+"deg) rotateX("+ay+"deg)");
    });

  }

}
